export enum headerMenuTypeEnum {
  home = 'home',
  trade = 'trade',
}

/**
 * 试玩账号头部菜单限制路径
 */
export const ToolbarTrialAccountLimitPath = [
  /** 跟单 */
  '/copy-trading',
  /** c2c 交易 */
  '/c2c/fast-trade',
  /** 邀请返佣 */
  '/agent',
  /** 代理中心 */
  '/agent/agency-center',
  /** 福利中心 */
  '/welfare-center',
]
